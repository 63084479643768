import { useCompanyLogo, useIsMobile } from "../../hooks";
import HeaderContent from "../header-content";
import "./header-component.scss";

const HeaderComponent = ({ headerContent, className = "" }) => {
  const { darkLogo } = useCompanyLogo();
  const isMobile = useIsMobile();
  return (
    <div className={`common-header ${className}`}>
      <div className="common-header__inner-container">
        {headerContent || <HeaderContent display={false} />}
        {!isMobile && darkLogo && (
          <div className="header-logo">
            <img alt="site-logo" src={darkLogo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderComponent;
