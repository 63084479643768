import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { Router } from "@reach/router";
import classNames from "classnames";
import { navigate } from "gatsby";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useMobileHeaderTabsStyles } from "../../commons/transactions.common";
import { PageBody, PageBodyContent } from "../../components";
import HeaderComponent from "../../components/HeaderComponent/header-component";
import ConnectBankModal, {
  DefaultConnectBank,
  DefaultCreateScenario,
} from "../../components/connect-bank-modal";
import ScenarioItemContext from "../../context/scenario-item-context";
import { useCompanyInfo, useIsDesktopSmall, useIsTablet } from "../../hooks";
import { useIsNotConnectingToAnyBank } from "../../hooks/account.hooks";
import { useScenarioList } from "../../hooks/scenario.hooks";
import { useTabStyles } from "../../utils/styles-util";
import ApplicationProcess from "./application-in-process";
import BorrowingCapacity from "./borrowing-capacity";
import ScenarioFilter from "./borrowing-capacity/components/scenario-filter";
import ScenarioList from "./borrowing-capacity/components/scenario-list";
import StartLoanApplication from "./start-loan-application";
import scenarioVideo from "../../videos/scenario.mp4";

const Finance = ({ location }) => {
  const isTablet = useIsTablet();
  const isDesktopSmall = useIsDesktopSmall();
  const [applicationStep, setApplicationStep] = useState(0);
  const [isUploadDocument, setIsUploadDocument] = useState(false);
  const tabsStyles = useTabStyles();
  const mobileHeaderTabsStyles = useMobileHeaderTabsStyles();
  const [scenarioFilterData, setScenarioFilterData] = useState({});
  const [scenarioFilterOpen, setScenarioFilterOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: scenarioList = [] } = useScenarioList();

  const isNotConnectingToAnyBank = useIsNotConnectingToAnyBank();
  const { pathname = "" } = location;
  const [tabIndex, setTabIndex] = useState("");
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useLayoutEffect(() => {
    switch (pathname) {
      case "/finance/loan-application":
        setTabIndex("loan-application");
        break;
      case "/finance/application-process":
        setTabIndex("application-process");
        break;
      default:
        setTabIndex("buying-power");
        break;
    }
  }, [pathname]);

  useEffect(() => {
    switch (tabIndex) {
      case "loan-application":
        if (pathname !== "/finance/loan-application") {
          navigate("/finance/loan-application");
        }

        break;
      case "application-process":
        if (pathname !== "/finance/application-process") {
          navigate("/finance/application-process");
        }
        break;
      default:
        if (pathname !== "/finance/buying-power") {
          navigate("/finance/buying-power");
        }
        break;
    }
  }, [tabIndex]);

  const { data } = useCompanyInfo();
  const isHaveConnective = data?.isHaveConnective;

  const handleOpenScenario = (value) => {
    setScenarioFilterOpen(value);
  };
  return (
    <ScenarioItemContext.Provider
      value={{
        dialogOpen,
        setDialogOpen,
        scenarioFilterOpen,
        setScenarioFilterOpen,
        scenarioFilterData,
        setScenarioFilterData,
        setTabIndex,
        tabIndex,
        applicationStep,
        setApplicationStep,
        isUploadDocument,
        setIsUploadDocument,
      }}
    >
      <div className="page-container">
        <div className="page-content">
          {!isDesktopSmall && scenarioList.length > 0 && (
            <ScenarioList
              setScenarioFilterOpen={handleOpenScenario}
              setTabIndex={setTabIndex}
            />
          )}
          <PageBody>
            {!scenarioList.length > 0 ? (
              <>
                {!isTablet && <HeaderComponent />}
                <ConnectBankModal
                  videoBackground={scenarioVideo}
                  callToActionButton={
                    isNotConnectingToAnyBank ? (
                      <DefaultConnectBank />
                    ) : (
                      <DefaultCreateScenario />
                    )
                  }
                />
              </>
            ) : (
              <>
                {!isTablet && (
                  <div className="da-header">
                    <HeaderComponent
                      headerContent={
                        <Tabs
                          value={tabIndex}
                          onChange={handleChange}
                          indicatorColor="secondary"
                          textColor="inherit"
                          variant="scrollable"
                          scrollButtons="auto"
                          TabIndicatorProps={{ children: <span /> }}
                          classes={{
                            root: tabsStyles.tabs,
                            flexContainer: tabsStyles.tabsFlexContainer,
                            indicator: classNames(
                              tabsStyles.indicator,
                              mobileHeaderTabsStyles.indicator
                            ),
                          }}
                        >
                          {[
                            {
                              key: "buying-power",
                              title: "Buying Power",
                              visible: true,
                            },
                            {
                              key: "loan-application",
                              title: "Start Loan Application",
                              visible: isHaveConnective,
                            },
                            {
                              key: "application-process",
                              title: "Application In Process",
                              visible: true,
                            },
                          ].map((tabItem) =>
                            tabItem?.visible ? (
                              <Tab
                                key={tabItem.title}
                                label={tabItem.title}
                                value={tabItem.key}
                              />
                            ) : (
                              <div key={tabItem.title} />
                            )
                          )}
                        </Tabs>
                      }
                    />
                  </div>
                )}
                <PageBodyContent>
                  <Router
                    primary={false}
                    basepath="/finance"
                    className="finance-content"
                  >
                    <BorrowingCapacity path="/buying-power" />
                    <StartLoanApplication path="/loan-application" />
                    <ApplicationProcess path="/application-process" />
                  </Router>
                </PageBodyContent>
              </>
            )}
          </PageBody>
          <ScenarioFilter
            handleOpenScenario={handleOpenScenario}
            scenarioList={scenarioList}
          />
        </div>
      </div>
    </ScenarioItemContext.Provider>
  );
};

export default Finance;
