import { Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";
import {
  DEFAULT_MONTHLY_RENT,
  DEFAULT_SIGNUP_BUYING_GOAL,
  PROPERTY_PURPOSE,
  scenarioTypes,
} from "../commons/enum";
import DataLoadingContext from "../context/data-loading-context";
import ScenarioItemContext from "../context/scenario-item-context";
import { SelectedPropertyContext } from "../context/selected-property-context";
import {
  useExternalAccountsConnectUI,
  useIsNotConnectingToAnyBank,
} from "../hooks/account.hooks";
import { useIsMobile } from "../hooks/common.hooks";
import {
  useAddPropertyMutation,
  usePropertyList,
  useUpdatePropertyMutation,
} from "../hooks/property.hooks";
import {
  useScenarioList,
  useUpdateUserEmployment,
} from "../hooks/scenario.hooks";
import { useMyInfo } from "../hooks/user.hooks.ts";
import { Anz, Boq, CommonWealth, Mac, Nab } from "../images";
import AddScenarioButton from "../pages/finance/borrowing-capacity/components/add-scenario-button";
import { getCurrentPath } from "../utils";
import { buildErrorMessage } from "../utils/property-util";
import ExternalAccountsConnectUiDialog from "./ExternalAccountsConnectUIDialog/external-accounts-connect-ui-dialog";
import UpdateProperty from "./PropertyModal/update-property";
import AddAccountButton from "./add-account-button";
import AddPropertyInput from "./add-property-input";

const doNothing = () => {};

const DEFAULT_CONNECT_BANK_LOGOS = [
  { alt: "CommonWealth", src: CommonWealth },
  { alt: "Anz", src: Anz },
  { alt: "Nab", src: Nab },
  { alt: "Mac", src: Mac },
  { alt: "Boq", src: Boq },
];
export const DefaultConnectBank = ({
  title = "Connect Bank Accounts",
  buttonTitle = "Connect Bank",
  logos = DEFAULT_CONNECT_BANK_LOGOS,
}) => {
  const { setAppState } = useContext(DataLoadingContext);
  const { data: userInfo = {} } = useMyInfo();
  const { firstName = "" } = userInfo;
  const { data: scenarioList = [] } = useScenarioList();
  const {
    connect: connectExternalAccounts,
    uiShown: externalAccountsConnectUIShown,
    connectSuccessfullyRef,
    targetElementIdRef: externalAccountsConnectUIIdRef,
  } = useExternalAccountsConnectUI();
  const updateUserEmployment = useUpdateUserEmployment();
  const isMobile = useIsMobile();
  const handleOnSuccess = () => {
    updateUserEmployment.mutate();
    connectSuccessfullyRef.current = true;
    if (scenarioList.length <= 0) {
      setAppState({
        pendingToCreateScenarios: [
          {
            buyingGoal: DEFAULT_SIGNUP_BUYING_GOAL,
            firstName,
          },
          {
            scenarioType: scenarioTypes.BUY_AN_INVESTMENT,
            buyingGoal: DEFAULT_SIGNUP_BUYING_GOAL,
            monthlyRent: DEFAULT_MONTHLY_RENT,
            firstName,
          },
        ],
      });
    }
  };

  const defaultOnClick = () => {
    connectExternalAccounts({
      onSuccess: handleOnSuccess,
    });
  };
  return (
    <>
      <ExternalAccountsConnectUiDialog
        id={externalAccountsConnectUIIdRef.current}
        open={externalAccountsConnectUIShown}
      />
      {isMobile ? (
        <AddAccountButton title={buttonTitle} />
      ) : (
        <>
          <div className="default-page-content-input__header">{title}</div>
          <div
            className="default-connect-bank__container"
            onClick={defaultOnClick}
            onKeyDown={() => {}}
            role="button"
            tabIndex="0"
          >
            <div className="default-connect-bank__logo-container">
              {logos.map((logo) => (
                <div
                  key={logo.alt}
                  className={classNames("default-connect-bank__logo", {
                    "default-connect-bank__logo--small-padding":
                      logo.smallPadding,
                    "default-connect-bank__logo--large-padding":
                      logo.largePadding,
                    "default-connect-bank__logo--full-width": logo.fullWidth,
                  })}
                >
                  <img src={logo.src} alt={logo.alt} />
                </div>
              ))}
              <AddAccountButton onClick={doNothing} title={buttonTitle} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export const DefaultCreateScenario = () => {
  const { setScenarioFilterOpen } = useContext(ScenarioItemContext);
  return <AddScenarioButton handleOpenScenario={setScenarioFilterOpen} />;
};

export const DefaultAddProperty = () => {
  const isMobile = useIsMobile();
  const { enqueueSnackbar } = useSnackbar();
  const {
    error: propertyError,
    isError: propertyListError,
    data: propertyList,
  } = usePropertyList();

  const [params, setParams] = useState({
    purpose: PROPERTY_PURPOSE.INVEST,
  });
  const isPropertyListEmpty = useMemo(() => {
    if (propertyList?.length > 0) {
      return false;
    }
    return true;
  }, [propertyList]);

  const [
    isMissingPropertyInformation,
    setIsMissingPropertyInformation,
  ] = useState(false);
  const { selectedPropertyId, setSelectedPropertyId } = useContext(
    SelectedPropertyContext
  );
  const addProperty = useAddPropertyMutation();

  const handleUpdateParams = (value) => {
    setParams((prevParams) => ({ ...prevParams, ...value }));
  };

  const updateProperty = useUpdatePropertyMutation();
  const handleUpdateProperty = () => {
    updateProperty.mutate(
      {
        params,
        id: selectedPropertyId,
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Update property successfully", {
            variant: "success",
          });
        },
        onError: (error) => {
          const { data: { message = "" } = {} } = error;

          enqueueSnackbar(message, { variant: "error" });
        },
      }
    );
  };

  const handleCloseUpdateProperty = () => {
    setIsMissingPropertyInformation(false);
  };

  const handleAddProperty = () => {
    addProperty.mutate(params, {
      onSuccess: () => {
        enqueueSnackbar("The property has been added", { variant: "success" });
      },
      onError: (error) => {
        const {
          data: { message = "", statusCode, propertyDetail } = {},
        } = error;
        if (statusCode === 400 && message !== "Property is already existed") {
          const {
            bathrooms = 0,
            bedrooms = 0,
            carparks = 0,
            landarea = 0,
            propertyType,
            propertyId,
            address,
          } = propertyDetail;
          setParams((prevParam) => {
            return {
              ...prevParam,
              bathrooms: prevParam.bathrooms || bathrooms,
              bedrooms: prevParam.bedrooms || bedrooms,
              carparks: prevParam.carparks || carparks,
              landarea: prevParam.landarea || landarea,
              propertyType: prevParam.propertyType || propertyType,
              address,
            };
          });
          setSelectedPropertyId(propertyId);
          setIsMissingPropertyInformation(true);
        }
        enqueueSnackbar(
          buildErrorMessage(
            message === "Property is already existed"
              ? message
              : "Missing required fields."
          ),
          { variant: "error" }
        );
      },
    });
  };

  useEffect(() => {
    if (propertyListError) {
      const {
        data: { message = "", statusCode, propertyDetail } = {},
      } = propertyError;
      if (statusCode === 400 && message !== "Property is already existed") {
        const {
          bathrooms = 0,
          bedrooms = 0,
          carparks = 0,
          landarea = 0,
          propertyType,
          propertyId,
          address,
        } = propertyDetail;
        setParams((prevParam) => {
          return {
            ...prevParam,
            bathrooms,
            bedrooms,
            carparks,
            landarea,
            propertyType,
            address,
          };
        });
        setSelectedPropertyId(propertyId);
        setIsMissingPropertyInformation(true);
      }
    }
  }, [propertyListError]);

  return (
    <>
      {!isMobile && (
        <div className="default-page-content-input__header">
          Type In Address
        </div>
      )}
      {isPropertyListEmpty &&
      (isMissingPropertyInformation || propertyListError) ? (
        <div className="default-page-content-input__update-property">
          {/* Property list that has errors needs to be force-updated */}
          {!propertyListError && (
            <IconButton
              onClick={handleCloseUpdateProperty}
              aria-label="delete"
              style={{ position: "absolute", right: "0.5rem", top: "0.5rem" }}
            >
              <CloseIcon />
            </IconButton>
          )}
          <UpdateProperty
            isMissingData={isMissingPropertyInformation}
            isLoading={addProperty.isLoading || updateProperty.isLoading}
            params={params}
            handleUpdateParams={handleUpdateParams}
            handleUpdateProperty={
              propertyListError ? handleUpdateProperty : handleAddProperty
            }
          />
        </div>
      ) : (
        <AddPropertyInput
          isLoading={addProperty.isLoading}
          handleUpdateParams={handleUpdateParams}
          handleAddProperty={handleAddProperty}
        />
      )}
    </>
  );
};

const getContentByPath = (path = "", isNotConnectingToAnyBank = false) => {
  if (path.includes("transaction")) {
    return "Connect your bank accounts and see where you’re spending your money and your accounts health.";
  }
  if (path.includes("finance") && isNotConnectingToAnyBank) {
    return "Connect your bank accounts and find out your buying power and loan options.";
  }
  if (path.includes("finance") && !isNotConnectingToAnyBank) {
    return "Compare loan options and see how much you can borrow and at what interest rate.";
  }
  if (path.includes("shares-and-super")) {
    return "Connect your shares and super accounts and keep track of their performance and your net wealth.";
  }
  if (path.includes("property")) {
    return "Link your properties to get detailed evaluation reports and track your properties performance over time.";
  }
  return "Connect your bank accounts and build your own dashboard to show all your assets and investments.";
};

const DefaultMobileConnectBank = ({ callToActionButton, currentPath }) => {
  const isNotConnectingToAnyBank = useIsNotConnectingToAnyBank();
  return (
    <div className="default-mobile-connect-bank__content">
      <div className="default-page-content__header">
        Bring This Page To Life<span>.</span>
      </div>
      <div className="default-page-content__description">
        {getContentByPath(currentPath, isNotConnectingToAnyBank)}
      </div>
      {callToActionButton}
    </div>
  );
};

const ConnectBankModal = ({ videoBackground = "", callToActionButton }) => {
  const currentPath = getCurrentPath();
  const isMobile = useIsMobile();
  const isNotConnectingToAnyBank = useIsNotConnectingToAnyBank();

  return (
    <Grid container className="default-page__container">
      {isMobile ? (
        <>
          <DefaultMobileConnectBank
            callToActionButton={callToActionButton}
            currentPath={currentPath}
          />
          <ReactPlayer
            playing
            loop
            playsinline
            muted
            url={videoBackground}
            height="100%"
            style={{
              zIndex: -1,
            }}
            config={{
              file: {
                attributes: {
                  preload: "auto",
                },
              },
            }}
          />
        </>
      ) : (
        <>
          <Grid item md={6}>
            <div className="default-page-content__header">
              Bring This Page To Life<span>.</span>
            </div>
            <div className="default-page-content__description">
              {getContentByPath(currentPath, isNotConnectingToAnyBank)}
            </div>
            {callToActionButton}
          </Grid>
          <Grid item md={6}>
            <div className="sharing-content-image__container">
              <ReactPlayer
                playing
                loop
                muted
                url={videoBackground}
                height="100%"
                width="100%"
                config={{
                  file: {
                    attributes: {
                      preload: "auto",
                    },
                  },
                }}
              />
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ConnectBankModal;
