import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { formatNumberWithDollar } from "../utils/numberFormater";

const HeaderContent = ({
  name = "",
  value = 0,
  percent = 0,
  display = true,
}) => {
  return (
    <div>
      <div className="header-title">{name}</div>
      <div className={`header-value ${!display && `d-none`} `}>
        {formatNumberWithDollar(value)}
      </div>
      {percent > 0 && (
        <>
          <sup className="header-percent">
            <NumberFormat
              value={percent}
              displayType="text"
              suffix="%"
              prefix={percent > 0 ? "+" : ""}
            />
          </sup>
          <span className="header-percent">
            <NumberFormat
              value={percent}
              displayType="text"
              suffix="%"
              prefix={percent > 0 ? "+" : ""}
            />
          </span>
        </>
      )}
    </div>
  );
};

export default HeaderContent;

HeaderContent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  percent: PropTypes.number,
};
