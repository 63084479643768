import React, { useContext, useMemo } from "react";
import HeaderContent from "../../../../components/header-content";
import DataLoadingContext from "../../../../context/data-loading-context";
import { FinanceContext } from "../../../../context/finance-context";
import ScenarioItemContext from "../../../../context/scenario-item-context";
import {
  useScenarioProductListByScenarioId,
  useSortedScenarioList,
} from "../../../../hooks/scenario.hooks";
import AddScenarioButton from "./add-scenario-button";
import RemoveScenarioComponent from "./remove-scenario-component";
import ScenarioItem from "./scenario-item";

const ScenarioList = ({
  setScenarioFilterOpen = () => {},
  setTabIndex = () => {},
}) => {
  const { setDialogOpen, dialogOpen, tabIndex } = useContext(
    ScenarioItemContext
  );

  const handleOpenDialog = (value) => {
    setDialogOpen(value);
  };

  const { pendingToCreateScenarios } = useContext(DataLoadingContext);
  const scenarioList = useSortedScenarioList();
  const { selectedScenarioId, setSelectedScenarioId } = useContext(
    FinanceContext
  );
  const { data: productList } = useScenarioProductListByScenarioId(
    selectedScenarioId
  );

  const handleSelectScenario = (value) => {
    setSelectedScenarioId(value);
  };

  const maxBuyingPower = useMemo(() => {
    return productList?.length > 0
      ? Math.max(...productList.map((item) => item.maxBuyingPower))
      : 0;
  }, [productList]);

  const handleOpenScenario = (value) => {
    setTabIndex((prevState) => {
      if (prevState !== "buying-power") {
        return "buying-power";
      }
      return prevState;
    });
    setScenarioFilterOpen(value);
  };

  return (
    <div className="da-body-linked-account">
      {scenarioList?.length > 0 && (
        <div className="linked-account-header la-container">
          <HeaderContent name="Max Buying" value={maxBuyingPower} />
        </div>
      )}
      <div className="linked-account-header la-container">Scenarios</div>
      {scenarioList?.length > 0 &&
        scenarioList.map((item) => {
          return (
            <ScenarioItem
              key={`ScenarioItem+${item.id}`}
              scenarioDetail={item}
              selectedScenario={selectedScenarioId}
              handleSelectScenario={handleSelectScenario}
              isShowEditScenarioDialog={tabIndex === "buying-power"}
            />
          );
        })}
      <AddScenarioButton
        disabled={pendingToCreateScenarios?.length > 0}
        handleOpenScenario={handleOpenScenario}
      />
      <RemoveScenarioComponent
        open={dialogOpen}
        handleDialogClose={handleOpenDialog}
      />
    </div>
  );
};

export default ScenarioList;
