import { makeStyles } from "@material-ui/core";
import { primaryColor } from "../scss/colors.scss";

export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

export const useTabStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: "none",
    height: "100%",
    alignSelf: "stretch",
    backgroundColor: "transparent",
  },
  tabsFlexContainer: {
    height: "100%",
  },
  indicator: {
    backgroundColor: primaryColor,
  },
  tabs: {
    height: "100%",
  },
  tab: {
    color: "rgba(0, 0, 0, 0.54)",
    textTransform: "none",
    [theme.breakpoints.down("md")]: {
      "&$tab": {
        height: "100%",
      },
    },
  },
  selectedTab: {
    color: "#000000",
  },
}));
