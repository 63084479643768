import React, { useContext, useMemo } from "react";
import Slider from "react-slick";
import { LINE_CHART_DASH } from "../../../../commons/enum";
import {
  mobileChartSliderSettings,
  mobileSliderSettings,
} from "../../../../commons/slider-mobile-setting";
import { LineChartMobileWrapper } from "../../../../components/line-chart-mobile-wrapper";
import { FinanceContext } from "../../../../context/finance-context";
import {
  useBuyingPowerHistoryByScenario,
  useScenarioProductListByScenarioId,
} from "../../../../hooks/scenario.hooks";
import { primaryColor } from "../../../../scss/colors.scss";
import {
  buildChartDataFromObject,
  generateBuyingGoalData,
  generateReduceData,
} from "../../../../utils/chart-util";
import sortProductList from "../../../../utils/sort-product-list";
import BorrowCapacityItemGroup from "./borrow-capacity-item-group";
import OutcomeDescription from "./outcome-description";
import TopThreeProduct from "./top-three-product";

const BorrowCapacityMobileView = () => {
  const { selectedScenarioId, selectedScenarioDetail } = useContext(
    FinanceContext
  );
  const { scenarioObject = {} } = selectedScenarioDetail || {};
  const { lvrSetting = {}, loanType } = scenarioObject;
  const { cashTowardProperty = 0 } = lvrSetting;

  const { data: buyingPowerHistory } = useBuyingPowerHistoryByScenario(
    selectedScenarioId
  );

  const maxLoanHistoryChartData = useMemo(() => {
    return buildChartDataFromObject(buyingPowerHistory?.borrowCapacityList);
  }, [buyingPowerHistory?.borrowCapacityList]);

  const maxBuyingPowerChartData = useMemo(() => {
    return buildChartDataFromObject(buyingPowerHistory?.maxBuyingPowerList);
  }, [buyingPowerHistory?.maxBuyingPowerList]);

  const {
    isLoading: isLoadingScenarioProductList,
    isFetching: isFetchingScenarioProductList,
    error: scenarioProductListError,
    data: productList,
  } = useScenarioProductListByScenarioId(selectedScenarioId);

  const displayProductList = useMemo(() => {
    return productList;
  }, [productList]);

  const isGettingScenarioList =
    isLoadingScenarioProductList || isFetchingScenarioProductList;

  const sortedDisplayProductList = useMemo(() => {
    if (displayProductList?.length > 0) {
      return sortProductList(displayProductList, loanType);
    }
    return [];
  }, [displayProductList, loanType]);

  const maxTotalAmount = useMemo(() => {
    return sortedDisplayProductList?.length > 0
      ? Math.max(
          ...sortedDisplayProductList.map(
            (productItem) => productItem.maxLoanAmount
          )
        )
      : 0;
  }, [sortedDisplayProductList]);

  const maxBuyingPower = useMemo(() => {
    return sortedDisplayProductList?.length > 0
      ? Math.max(
          ...sortedDisplayProductList.map(
            (productItem) => productItem.maxBuyingPower
          )
        )
      : 0;
  }, [sortedDisplayProductList]);

  const chartSliderdata = [
    {
      title: "Real Buying Power",
      chartData: [
        {
          label: "Stretching it",
          data: maxBuyingPowerChartData,
          borderWidth: 1,
          lineSpacing: LINE_CHART_DASH,
        },
        {
          label: "Comfortably",
          data: generateReduceData(maxBuyingPowerChartData),
        },
        {
          label: "Buying Goal",
          data: generateBuyingGoalData(
            maxBuyingPowerChartData,
            scenarioObject?.lvrSetting?.estimateProperty
          ),
          color: primaryColor,
          borderWidth: 1,
        },
      ],
      titleValue: maxBuyingPower,
    },
    {
      title: "Max Loan",
      chartData: [
        {
          label: "Stretching it",
          data: maxLoanHistoryChartData,
          borderWidth: 1,
          lineSpacing: LINE_CHART_DASH,
        },
        {
          label: "Comfortably",
          data: generateReduceData(maxLoanHistoryChartData),
        },
        {
          label: "Buying Goal",
          data: generateBuyingGoalData(
            maxLoanHistoryChartData,
            scenarioObject?.lvrSetting?.estimateProperty
          ),
          color: primaryColor,
          borderWidth: 1,
        },
      ],
      titleValue: maxTotalAmount,
    },
  ];

  return (
    <div className="bc-scenario-main">
      <div className="mobile-chart-container">
        <Slider
          {...mobileChartSliderSettings}
          className="property-detail-slider"
        >
          {chartSliderdata.map((chart) => {
            return (
              <LineChartMobileWrapper
                key={chart.title}
                chartTitle={chart.title}
                subTitle="(Last 1 yr)"
                titleValue={chart.titleValue}
                chartData={chart.chartData}
              />
            );
          })}
        </Slider>
      </div>
      <div className="mobile-chart-container">
        <Slider {...mobileSliderSettings} className="property-detail-slider">
          <>
            <div className="card-title">Top Three Loans</div>
            <div className="card-container">
              <TopThreeProduct
                isLoading={isGettingScenarioList}
                isError={scenarioProductListError}
                maxLoan={maxTotalAmount}
                cashTowardProperty={cashTowardProperty}
                productList={sortedDisplayProductList.slice(0, 3)}
                scenarioObject={scenarioObject}
                loanType={loanType}
              />
            </div>
          </>
          <>
            <div className="card-title">By The Numbers</div>
            <div className="card-container">
              <BorrowCapacityItemGroup
                productList={productList || []}
                isLoading={isGettingScenarioList}
              />
            </div>
          </>
          <>
            <div className="card-title">Outcome</div>
            <div className="outcome-description-container card-container">
              <OutcomeDescription
                scenarioObject={scenarioObject}
                maxLoan={maxTotalAmount}
                cashTowardProperty={cashTowardProperty}
              />
            </div>
          </>
        </Slider>
      </div>
    </div>
  );
};

export default BorrowCapacityMobileView;
