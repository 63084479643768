import React from "react";
import { LoadingBackdrop } from "../../../components/loading-backdrop";
import { useIsTablet } from "../../../hooks/common.hooks";
import { useScenarioList } from "../../../hooks/scenario.hooks";
import ApplicationMobileLayout from "../components/application-mobile-layout";
import BorrowCapacityMainScreen from "./components/borrow-capacity-main-screen";
import BorrowCapacityMobileView from "./components/borrow-capacity-mobile-view";

const BorrowingCapacity = () => {
  const isTablet = useIsTablet();
  const {
    data: scenarioList,
    isLoading: gettingScenarioList,
  } = useScenarioList();

  return (
    <>
      <ApplicationMobileLayout>
        {scenarioList?.length > 0 &&
          (isTablet ? (
            <BorrowCapacityMobileView />
          ) : (
            <BorrowCapacityMainScreen />
          ))}

        {gettingScenarioList && (
          <div className="position-relative w-100">
            <LoadingBackdrop />
          </div>
        )}
        {scenarioList?.length === 0 && !gettingScenarioList && (
          <div className="no-lender-option">
            Please create a scenario to see your buying power.
          </div>
        )}
      </ApplicationMobileLayout>
    </>
  );
};

BorrowingCapacity.propTypes = {};

export default BorrowingCapacity;
